import { create } from 'zustand';
import { tavusPersonaApi } from '../api';
import { toast } from 'react-toastify';
import { ITavusPersona } from './tavusPersonaTypes';

type State = {
  currentPersona: ITavusPersona | null;
  tavusPersonas: ITavusPersona[];
  tavusPersonasLoading: boolean;
  personaEmbedModal: string | null;
};
type Actions = {
  fetchPersonas: () => Promise<void>;
  fetchOne: (id: string) => Promise<ITavusPersona | undefined>;
  setCurrentPersona: (persona: ITavusPersona) => void;
  togglePersonaEmbedModal: (personaId: string | null) => void;
};

type Store = State & Actions;

export const tavusPersonaStore = create<Store>((set, get) => ({
  currentPersona: null,
  tavusPersonas: [],
  tavusPersonasLoading: false,
  personaEmbedModal: null,
  setCurrentPersona: (persona) => {
    set({ currentPersona: persona });
  },
  togglePersonaEmbedModal: (personaId) => {
    set({ personaEmbedModal: personaId });
  },
  fetchPersonas: async () => {
    set({ tavusPersonasLoading: true });

    try {
      const tavusPersonas = await tavusPersonaApi.fetchAll();

      set({ tavusPersonas });
    } catch (error) {
      toast.error(
        'There was a problem when we tried to get personas list. Please try again later.'
      );
    } finally {
      set({ tavusPersonasLoading: false });
    }
  },
  fetchOne: async (id: string) => {
    set({ tavusPersonasLoading: true });
    try {
      return await tavusPersonaApi.fetch(id);
    } catch (error) {
      toast.error(
        'There was a problem when we tried to get persona details. Please try again later.'
      );
    } finally {
      set({ tavusPersonasLoading: false });
    }
  }
}));
